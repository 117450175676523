import React, { Component } from "react";
import favicon from "../common-components/assets/favicon.svg";
import brasao from "../common-components/assets/brasao.svg";
import signature from "../common-components/assets/signature.svg";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.setState({});
  }

  render() {
    return (
      <>
        <nav>
          <div className="navbar-container">
            <div className="subbar-left">
              <img
                src={favicon}
                alt="Brasão da Fraternidade"
                aria-hidden="true"
                className="favicon"
              />
              <span className="nav-title">Spinkers</span>
            </div>
            <div className="subbar-right">
              <a
                className="custom-link"
                target="_blank"
                href="https://drive.google.com/file/d/1wOCvERKjTy2Im0K67Ii-tOfUpOmnCK6n/view?usp=sharing"
              >
                Estatuto
              </a>
            </div>
          </div>
        </nav>
        <div className="divzona">
          <div className="content">
            <h1>Quem somos?</h1>
            <div className="post-info">
              <author className="author">Lucas lopes Spinkers</author>
              <br />
              <city className="city">Jundiaí - SP</city>
              <br />
              <br />
              <date className="date">26 Mar 2020</date>
            </div>
            <article>
              Spinkers é uma fraternidade de pessoas determinadas a fazer o bem
              e que busca a evolução humana, social e profissional. Juntos nós
              crescemos em poder e união, honra e lealdade.
              <br />
              <br />
              Somos uma entidade que repudia todo e qualquer tipo de
              preconceito, temos convicção de que todo ser humano tem a mesma
              importância independente de crença, nacionalidade ou ideologia.
              <br />
              <br />A Organização Spinkers, doravante neste documento denominada
              Fraternidade, tem como objetivos:
              <div className="custom-list">
                <ol>
                  <li>
                    Unir forças para transformar o mundo através da mensagem e
                    do exemplo.
                  </li>
                  <li>
                    Ajudar uns aos outros afim de obter e prover crescimento
                    pessoal.
                  </li>
                  <li>
                    Aproximar pessoas de objetivos equivalentes e cultivar o bom
                    relacionamento.
                  </li>
                  <li>
                    Admoestar e exortar membros para que vivam mediante a
                    valores e princípios sólidos.
                  </li>
                  <li>
                    Conservar o que efetivamente funciona e reformar aquilo que
                    necessita de mudança.
                  </li>
                </ol>
              </div>
            </article>

            <h1>Nossos valores:</h1>

            <article>
              Como toda organização séria, nós temos valores e os seguimos
              religiosamente, conheça-os e verifique se você se identifica
              conosco:
              <div className="custom-list">
                <ol>
                  <li>Ordem e Progresso.</li>
                  <li>Família.</li>
                  <li>Honra.</li>
                  <li>Justiça.</li>
                  <li>Respeito.</li>
                </ol>
              </div>
            </article>
            <footer>
              <img
                src={brasao}
                alt="Brasão da Fraternidade"
                aria-hidden="true"
              />
              <div className="signature">
                <img
                  src={signature}
                  alt="Assinatura do presidente Lucas Lopes Spinkers"
                  aria-hidden="true"
                />
                <br />
                <name>Lucas Lopes Spinkers</name>
                <br />
                <br />
                <contact>contato@spinkers.com</contact>
              </div>
            </footer>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
